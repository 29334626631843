import moxie from 'js/moxie';
import _ from 'js/underscore';
window._ = _;
_.templateSettings = {
  interpolate: /\{\{(.+?)\}\}/g
};

$(document).ready(function() {
  var LANS = { zh: '中文', en: '英文', jp: '日文', kr: '韩文' };
  var MAPS = {
    en: ['zh'],
    zh: ['en', 'jp', 'kr'],
    jp: ['zh'],
    kr: ['zh']
  };
  var font_size = {
    source: 14,
    target: 14
  };
  var image_records = [];
  var image_width = 0;
  var image_height = 0;

  var init_select_options = function() {
    var source = $('#source').val();
    var allows = MAPS[source];
    $('#target option').each(function(i, obj) {
      var $obj = $(obj);
      var val = $obj.attr('value');
      if (allows.indexOf(val) > -1) {
        $obj.prop('disabled', false);
      } else {
        $obj.prop('disabled', true);
      }
      if (val === allows[0]) $obj.prop('selected', true);
    });
  };
  init_select_options();

  $('#source').change(function () {
    init_select_options();
  });

  var fi = new moxie.file.FileInput({
    browse_button: 'upload',
    accept: [
      {title: 'Images', extensions: 'jpg,bmp,png'}
    ]
  });
  fi.onchange = function(e) {
    upload_picture(e.target.files);
  };
  fi.init();
  var fd = new moxie.file.FileDrop('upload');
  fd.ondrop = function() {
    upload_picture(this.files);
  };
  fd.init();

  var find_form_items = function($target) {
    var args = {};
    $($target).each(function(i, obj) {
      var $obj = $(obj);
      if ($obj.attr('name'))
        args[$obj.attr('name')] = $obj.val() || '';
    });
    return args;
  };

  var upload_picture = function(files) {
    if (files.length > 1) {
      show_toast('每次只能上传一张图片！');
      return;
    }
    var img = new moxie.image.Image();
    img.onload = function() {
      var base64 = img.getAsDataURL();
      var blob = img.getAsBlob();
      image_width = img.width;
      image_height = img.height;
      send_upload_request(blob, base64);
    };
    img.load(files[0]);
  };

  var mergeFormData = function(formData) {
    var args = find_form_items('.upload_form input');
    $.each(args, function(k, v) {
      formData.append(k, v);
    });
    formData.append('source', $('#source').val());
    formData.append('target', $('#target').val());
    return formData;
  };

  var handle_upload_result = function(base64, res) {
    $('.loading').addClass('d-none');
    if (parseInt(res.status) !== 200) {
      $('.upload_form').removeClass('d-none');
      $('.download_box').removeClass('d-none');
      show_toast(res.statusText, '出错了！', 10000);
      return;
    }
    var data = JSON.parse(res.response);
    if (!data || data.ret !== 0) {
      $('.upload_form').removeClass('d-none');
      $('.download_box').removeClass('d-none');
      show_toast(data.msg || '未知错误！', '出错了！', 10000);
      return;
    }
    handle_ocr_results(base64, data.data);
  };

  var show_preview_image = function($target, callback) {

  };

  var handle_ocr_results = function(src, data) {
    image_records = data.image_records;
    if (!data || !image_records) {
      $('.upload_form').removeClass('d-none');
      $('.download_box').removeClass('d-none');
      show_toast('识别可能失败了！');
      return;
    }
    if (image_records.length === 0) {
      $('.upload_form').removeClass('d-none');
      $('.download_box').removeClass('d-none');
      show_toast('可能没有识别到相关信息！');
      return;
    }
    var image_html = '<img src="' + src + '" alt="预览图" class="img-fluid">';
    $('.preview_source').removeClass('d-none').find('.preview_image').html(image_html);
    $('.preview_target').removeClass('d-none').find('.preview_image').html(image_html);
    $('.ocr_again').removeClass('d-none');
    show_ocr_results();
  };

  var show_ocr_results = function() {
    $('.preview_text').remove();
    var $tpl = _.template($('.template_text_line').val());

    var $source_img = $('.preview_source .preview_image img');
    $source_img.one('load', function() {
      var source_ratio = $source_img.width() / image_width;
      $.each(image_records, function(i, rec) {
        var s_top = ratio(rec.y, source_ratio);
        var s_left = ratio(rec.x, source_ratio);
        var s_html = $tpl({
          top: s_top,
          left: s_left,
          text: rec.source_text
        });
        $('.preview_source .preview_image').append(s_html);
      });
      init_preview_text_line_click('.preview_source .preview_text');
    });

    var $target_img = $('.preview_target .preview_image img');
    $target_img.one('load', function() {
      var target_ratio = $target_img.width() / image_width;
      $.each(image_records, function(i, rec) {
        var t_top = ratio(rec.y, target_ratio);
        var t_left = ratio(rec.x, target_ratio);
        var t_html = $tpl({
          top: t_top,
          left: t_left,
          text: rec.target_text
        });
        $('.preview_target .preview_image').append(t_html);
      });
      init_preview_text_line_click('.preview_target .preview_text');
    });
  };

  var init_preview_text_line_click = function($target) {
    $($target).dblclick(function() {
      copy_content($.trim($(this).text()));
      document.getSelection().removeAllRanges();
      show_toast('复制成功！');
    });
  };

  $('.btn_show_hide_text').click(function() {
    var $t = $(this);
    var type = $t.data('type');
    var css = $t.data('css');
    $('.preview_' + type + ' .preview_text').each(function(i, obj) {
      var $line = $(obj);
      if ($line.hasClass(css))
        $line.removeClass(css);
      else
        $line.addClass(css);
    });
    return false;
  });

  $('.btn_copy_text').click(function() {
    var $t = $(this);
    var type = $t.data('type');
    var text = '';
    $('.preview_' + type + ' .preview_text').each(function(i, obj) {
      text += $.trim($(obj).text()) + "\r\n";
    });
    copy_content(text);
    document.getSelection().removeAllRanges();
    show_toast('复制成功！');
    return false;
  });

  var set_picture_width_height = function(picture, callback) {
    var img = new moxie.image.Image();
    img.onload = function() {
      image_width = img.width;
      image_height = img.height;
      if (callback) callback();
    };
    img.load(picture);
  };

  $('.download_form').submit(function() {
    var $form = $(this);
    var picture = $.trim($('#picture').val());
    var args = find_form_items('.download_form input, .download_form select');
    if (picture === '') {
      picture = test_picture;
      $('#picture').val(picture);
      args.picture = picture;
    }
    $('.loading').removeClass('d-none');
    $('.upload_form').addClass('d-none');
    $('.download_box').addClass('d-none');
    set_picture_width_height(picture, function() {
      $.ajax({
        url: $form.attr('action'),
        type: 'POST',
        data: args,
        dataType: 'JSON',
        error: function() {
          $('.loading').addClass('d-none');
          $('.upload_form').removeClass('d-none');
          $('.download_box').removeClass('d-none');
          show_toast('请求错误或请求过程中断！');
        },
        success: function(res) {
          handle_download_result(picture, res);
        }
      });
    });
    return false;
  });

  var handle_download_result = function(picture, res) {
    $('.loading').addClass('d-none');
    if (!res || res.ret !== 0) {
      $('.upload_form').removeClass('d-none');
      $('.download_box').removeClass('d-none');
      show_toast(res.msg || '未知错误！', '出错了！', 10000);
      return;
    }
    handle_ocr_results(picture, res.data);
  };

  $('.btn_font_operation').click(function() {
    var $t = $(this);
    var type = $t.data('type');
    var opt = $t.data('opt');
    var fs = font_size[type];
    if (opt === '+')
      fs += 2;
    else
      fs -= 2;
    if (fs < 12) fs = 12;
    if (fs > 24) fs = 24;
    font_size[type] = fs;
    $('.preview_' + type + ' .preview_text').each(function(i, obj) {
      $(obj).css('font-size', fs + 'px');
    });
    return false;
  });

  var ratio = function(a, b) {
    return a * b;
  };

  var copy_content = function(content) {
    // 创建元素用于复制
    var aux = document.createElement('textarea');
    // 设置元素内容
    // aux.setAttribute('value', content);
    aux.value = content;
    // 将元素插入页面进行调用
    document.body.appendChild(aux);
    // 复制内容
    aux.select();
    // 将内容复制到剪贴板
    document.execCommand('copy');
    // 删除创建元素
    document.body.removeChild(aux);
  };

  $('.ocr_again button').click(function() {
    $('.upload_form').removeClass('d-none');
    $('.download_box').removeClass('d-none');
    $('.ocr_again').addClass('d-none');
    $('.preview_source').addClass('d-none');
    $('.preview_target').addClass('d-none');
  });

  var show_toast = function(content, title, delay) {
    title ||= '提示';
    delay ||= 4000;
    $('.toast-title').html(title);
    $('.toast-body').html(content);
    $('.toast').toast({ delay: delay }).toast('show');
  };

  $('#picture').on('paste', function (e) {
    var items = (e.clipboardData || e.originalEvent.clipboardData).items;
    for(var i in items) {
      var item = items[i];
      if (item.kind === 'file') {
        setTimeout(function() {
          $('#picture').val('');
        }, 10);
        var blob = item.getAsFile();
        var reader = new FileReader();
        reader.onload = function (e) {
          var base64 = e.target.result;
          var img = new moxie.image.Image();
          img.onload = function() {
            image_width = img.width;
            image_height = img.height;
            send_upload_request(img.getAsBlob(), base64);
          };
          img.load(base64);
        };
        reader.readAsDataURL(blob);
      }
    }
  });

  var send_upload_request = function(blob, base64) {
    var $form = $('.upload_form');
    $form.addClass('d-none');
    $('.loading').removeClass('d-none');
    $('.upload_form').addClass('d-none');
    $('.download_box').addClass('d-none');
    var upload_url = $form.attr('action');
    var formData = new moxie.xhr.FormData();
    formData.append('picture', blob);
    var xhr = new moxie.xhr.XMLHttpRequest();
    xhr.onload = function(res) {
      handle_upload_result(base64, res.target);
    };
    xhr.open('post', upload_url);
    xhr.send(mergeFormData(formData));
  };
});

$(document).on('turbolinks:load', function(){
});
